import React, { useEffect, useRef } from 'react';
import { BrowserRouter, Link,  useLocation } from 'react-router-dom';
import HeroHeader from '../HeroHeader';
import MenuBurger from '../MenuBurger';
import logo from './OPHIUCHUS_LOGO.svg';
import gsap from 'gsap'; 

function Toolbar(props) {
    const toolbar = useRef(null);
    const shopLink = useRef(null);
    const journalLink = useRef(null);
    const informationLink = useRef(null); 
    let location = useLocation();
    let subPath = location.pathname.split('/');
    let slashDisplay;
    let prodTypeLinks;

    console.log('toolbarp', props.productTypes);

    if(props.productTypes.map){
        prodTypeLinks = props.productTypes.map((type) => {
            return <Link to={`/shop?type=${type.toLowerCase()}`}><span className="underlined-anim">{type.toLowerCase()}</span></Link>
        })
    }
    function textIntro(event){
        event.preventDefault()
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        console.log(event.target.parentNode.parentNode);
        const anchors = event.target.parentNode.parentNode.querySelectorAll('nav a');
        gsap.to(anchors, {y:10, stagger:0.1, opacity:1})
    }
    function textOutro(event){
        event.preventDefault()
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        console.log(event.target.parentNode.parentNode);
        const anchors = event.target.parentNode.parentNode.querySelectorAll('nav a');
        gsap.to(anchors, {y:0, stagger:0.1, opacity:0})
    }

    function toolbarIntroAnim(){
        let animatedDropdowns = toolbar.current.querySelectorAll('.toolbar-intro-anim');
        //gsap.from(animatedDropdowns, {y:10, stagger:0.2, opacity:0})
        props.introAnimation.from(animatedDropdowns, {y:10, stagger:0.2, opacity:0})
    }
    useEffect(() => {
        toolbarIntroAnim();
    }, [])

    useEffect(() => {
        const links = document.querySelectorAll('.link-wrapper > a > span');

        [].forEach.call(links, (link) => {
            link.classList.remove('underlined');
            link.classList.add('underlined-anim');
        })

        if(location.pathname.startsWith('/information')){
            informationLink.current.classList.remove('underlined-anim');
            informationLink.current.classList.add('underlined');
        }
        if(location.pathname.startsWith('/journal')){
            journalLink.current.classList.remove('underlined-anim');
            journalLink.current.classList.add('underlined');
        }
        if(location.pathname.startsWith('/shop')){
            shopLink.current.classList.remove('underlined-anim');
            shopLink.current.classList.add('underlined');
        }
    }, [location])
console.log(location.pathname);

if(!location.pathname.startsWith('/shop?type=' && props.updateSlashDisplay)){
    props.updateSlashDisplay('');
}
        
    return (
        <div className="toolbar" ref={toolbar}>
            <div>
                <div className="desktop-links">
                    <div className="link-wrapper animated-dropdown toolbar-intro-anim"  onMouseEnter={ textIntro } onMouseLeave={ textOutro }>
                        <Link className ="toolbar-intro-anim" to="/shop"><span className="underlined-anim" ref={shopLink}>SHOP{props.slashDisplay.toUpperCase()}</span></Link>
                        <nav>
                            <Link onClick={() => { }} to="/shop"><span className="underlined-anim">View All</span></Link>
                            {prodTypeLinks}
                        </nav>
                    </div>
                    <div className="link-wrapper toolbar-intro-anim">
                        <Link to="/journal"><span className="underlined-anim" ref={journalLink}>JOURNAL</span></Link>
                    </div>
                    <div className ="link-wrapper toolbar-intro-anim">
                        <Link to="/information"><span className="underlined-anim" ref={informationLink}>INFORMATION</span></Link>
                    </div>
                    
                </div>
                
                <MenuBurger isMenuOpen={props.isMenuOpen} menuClickFunc={props.toggleMenuOpen} ></MenuBurger>
            </div>
            <div className="logo">
                <Link onClick={() => { props.setInProp(true)}} to="/"><img src={logo} /></Link>
            </div>
            <div className="cart-btn toolbar-intro-anim">
                <span onClick={() => {props.toggleCartOpen(!props.isCartOpen) }}>
                    ({props.cart.length})
                </span>
            </div>
        </div>
    );
}

export default Toolbar;