import React from 'react';

function SizingGuide(props) {

    return (
        <div  className="text-content" dangerouslySetInnerHTML={{ __html: props.children }} />
    )
    return (
        <>
            <div className="text-content">
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et ante lorem. Aenean in massa non metus sodales pharetra in ut nibh. Sed tempor placerat quam, iaculis semper erat rutrum at. Praesent laoreet suscipit metus sed volutpat. Etiam bibendum ligula ut ligula ultricies, vel feugiat nulla euismod. Aenean hendrerit enim erat, id euismod lorem facilisis non. Aliquam erat volutpat. Integer bibendum, lacus iaculis viverra cursus, eros metus fringilla diam, non placerat tortor massa ac nibh. Proin at enim quam. Sed fringilla condimentum est sed dignissim. Nullam dictum auctor lacinia. Suspendisse velit elit, interdum a massa a, fermentum pharetra odio. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
                </p>
                <p>
                    SALES@OPHIUCUSJEWELS.COM
                </p>
            </div>
            <table className="inline-2 col-3">
                <tr>
                    <th>
                        COUNTRY
                    </th>
                    <th>
                        SHIPPING COST
                    </th>
                    <th>
                        DELIVERY
                    </th>
                </tr>
                    
                <tr>
                    <td>
                        <p>
                            France
                        </p>  
                    </td>
                    <td>
                        <p>
                            $5.00
                        </p>
                    </td>
                    <td>
                        <p>
                            Next working day
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>
                            ZONE 1 
                            <br />
                            Curabitur in felis vel urna auctor ultrices. Curabitur malesuada pulvinar quam cursus finibus. Cras lacinia eros pellentesque pellentesque lacinia. 
                        </p>
                    </td>
                    <td>
                        <p>
                            $5.00
                        </p>
                    </td>
                    <td>
                        <p>
                            Next working day
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>
                            ZONE 1 
                            <br />
                            Curabitur in felis vel urna auctor ultrices. Curabitur malesuada pulvinar quam cursus finibus. Cras lacinia eros pellentesque pellentesque lacinia. 
                        </p>
                    </td>
                    <td>
                        <p>
                            $5.00
                        </p>
                    </td>
                    <td>
                        <p>
                            Next working day
                        </p>
                    </td>
                </tr>
            </table>
            <table className="inline-2 col-3">
                <tr>
                    <th>
                        COUNTRY
                    </th>
                    <th>
                        SHIPPING COST
                    </th>
                    <th>
                        DELIVERY
                    </th>
                </tr>
                    
                <tr>
                    <td>
                        <p>
                            France
                        </p>  
                    </td>
                    <td>
                        <p>
                            $5.00
                        </p>
                    </td>
                    <td>
                        <p>
                            Next working day
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>
                            ZONE 1 
                            <br />
                            Curabitur in felis vel urna auctor ultrices. Curabitur malesuada pulvinar quam cursus finibus. Cras lacinia eros pellentesque pellentesque lacinia. 
                        </p>
                    </td>
                    <td>
                        <p>
                            $5.00
                        </p>
                    </td>
                    <td>
                        <p>
                            Next working day
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>
                            ZONE 1 
                            <br />
                            Curabitur in felis vel urna auctor ultrices. Curabitur malesuada pulvinar quam cursus finibus. Cras lacinia eros pellentesque pellentesque lacinia. 
                        </p>
                    </td>
                    <td>
                        <p>
                            $5.00
                        </p>
                    </td>
                    <td>
                        <p>
                            Next working day
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>
                            ZONE 1 
                            <br />
                            Curabitur in felis vel urna auctor ultrices. Curabitur malesuada pulvinar quam cursus finibus. Cras lacinia eros pellentesque pellentesque lacinia. 
                        </p>
                    </td>
                    <td>
                        <p>
                            $5.00
                        </p>
                    </td>
                    <td>
                        <p>
                            Next working day
                        </p>
                    </td>
                </tr>
            </table>
        </>
    );
}

export default SizingGuide;