import React from 'react';

function Shipping(props) {
    return (
        <div  className="text-content" dangerouslySetInnerHTML={{ __html: props.children }} />
    )
    return (
        <div className="text-content">
            <table className="col-3">
                <tr>
                    <th>
                        COUNTRY
                    </th>
                    <th>
                        SHIPPING COST
                    </th>
                    <th>
                        DELIVERY
                    </th>
                </tr>
                    
                <tr>
                    <td>
                        <p>
                            France
                        </p>  
                    </td>
                    <td>
                        <p>
                            $5.00
                        </p>
                    </td>
                    <td>
                        <p>
                            Next working day
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>
                            ZONE 1 
                            <br />
                            Curabitur in felis vel urna auctor ultrices. Curabitur malesuada pulvinar quam cursus finibus. Cras lacinia eros pellentesque pellentesque lacinia. 
                        </p>
                    </td>
                    <td>
                        <p>
                            $5.00
                        </p>
                    </td>
                    <td>
                        <p>
                            Next working day
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>
                            ZONE 1 
                            <br />
                            Curabitur in felis vel urna auctor ultrices. Curabitur malesuada pulvinar quam cursus finibus. Cras lacinia eros pellentesque pellentesque lacinia. 
                        </p>
                    </td>
                    <td>
                        <p>
                            $5.00
                        </p>
                    </td>
                    <td>
                        <p>
                            Next working day
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>
                            ZONE 1 
                            <br />
                            Curabitur in felis vel urna auctor ultrices. Curabitur malesuada pulvinar quam cursus finibus. Cras lacinia eros pellentesque pellentesque lacinia. 
                        </p>
                    </td>
                    <td>
                        <p>
                            $5.00
                        </p>
                    </td>
                    <td>
                        <p>
                            Next working day
                        </p>
                    </td>
                </tr>
            </table>
            <h1>
                SHIPPING
            </h1>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et ante lorem. Aenean in massa non metus sodales pharetra in ut nibh. Sed tempor placerat quam, iaculis semper erat rutrum at. Praesent laoreet suscipit metus sed volutpat. Etiam bibendum ligula ut ligula ultricies, vel feugiat nulla euismod. Aenean hendrerit enim erat, id euismod lorem facilisis non. Aliquam erat volutpat. Integer bibendum, lacus iaculis viverra cursus, eros metus fringilla diam, non placerat tortor massa ac nibh. Proin at enim quam. Sed fringilla condimentum est sed dignissim. Nullam dictum auctor lacinia. Suspendisse velit elit, interdum a massa a, fermentum pharetra odio. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
            </p>
            <p>
                Curabitur in felis vel urna auctor ultrices. Curabitur malesuada pulvinar quam cursus finibus. Cras lacinia eros pellentesque pellentesque lacinia. Curabitur ullamcorper ipsum eu mattis facilisis. Nullam vitae mollis arcu. Vestibulum quis arcu arcu. Curabitur ac erat elit.
            </p>
            <p>
                Duis vehicula nunc eget felis mattis ultrices. Vivamus facilisis ut diam nec congue. Sed non quam semper, cursus ligula vitae, consequat ante. Proin facilisis ultrices elit sit amet pretium. Cras aliquet porttitor malesuada. Sed bibendum quis nisl eget venenatis. Aenean turpis nulla, pretium vel viverra eu, interdum sed libero.
            </p>
            <p>
                Etiam sit amet mollis leo. Vivamus vel ligula ac arcu consequat pretium. Maecenas faucibus ipsum metus, vel rhoncus felis ornare sed. Suspendisse scelerisque, dolor non consectetur tristique, justo mauris rutrum massa, non finibus augue justo et lectus. Aenean at urna luctus, facilisis velit et, efficitur nisi. Nullam id orci tempus odio mollis efficitur. Donec vestibulum lorem eu justo semper, vehicula semper metus posuere. Cras eget dolor semper, vestibulum lorem in, iaculis urna.
            </p>
            <p>
                Nulla fermentum tristique felis in ultricies. Sed et nisi pharetra, porta lacus vitae, iaculis metus. Fusce sagittis, sem sed hendrerit pellentesque, nisl eros porta nulla, ac accumsan sapien diam sed magna. Nulla vulputate leo id eleifend efficitur. Nam commodo fermentum nisi id posuere. Proin non gravida eros. Praesent laoreet massa nulla, eu vestibulum libero volutpat in. Vivamus lobortis mollis mauris, in lobortis mauris. Quisque bibendum lacus sed consectetur euismod. Aliquam pharetra nibh quis nisl pretium tincidunt.
            </p>
        </div>
    );
}

export default Shipping;