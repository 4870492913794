import React, { useRef ,useEffect } from 'react';
import HeroHeader from '../../HeroHeader';
import ProductListing from '../../ProductListing';
import gsap from 'gsap';

function HomePage(props) {

    console.log('hero',props.hero)
    function introAnim(){
        //gsap.from(animatedDropdowns, {y:10, stagger:0.2, opacity:0})
        //const pageInformationCols = pageInformation.current.children;

       // props.introAnimation.fromTo(pageInformationCols, {y:10, stagger:0.2, opacity:0}, {y:0, stagger:0.2, opacity:1})
    }

    function exitAnim(){
        //const pageInformationCols = pageInformation.current.children;
        //gsap.fromTo(pageInformationCols, {y:0, opacity:1}, {y:10, stagger:0.2, opacity:0})
        //console.log('play the ting')
    }

    useEffect(() => {
        animations(props.transState);

        console.log('appdata', props.appData)
        console.log('product list', props.productList)
    },[props.transState])

    function animations(type){
        if(type=='entered'){
            introAnim();
        }
        if(type=='exiting'){
            exitAnim();
            
        }
    }

    return (
        <>
            <HeroHeader transState={props.transState} mediaType='image' introAnimation={props.introAnimation} hero={props.hero}></HeroHeader>
            <ProductListing appData={props.appData} productList={props.productList} transState={props.transState} introAnimation={props.introAnimation} key='homepage' updateSlashDisplay={props.updateSlashDisplay} ></ProductListing> 
            </>
    );
}

export default HomePage;