import React from 'react';

function AddToCartBTN(props) {

    console.log('chosen-size', props.chosenSize);

    console.log('chosen-product', props.product);

    function cartCheck(){
        const index = props.cart.findIndex(cartItem => cartItem.prodID === props.product.prodID && cartItem.size === props.chosenSize.title);
        if(index === -1){
            // Function to update total 
            // props.updateCartTotal( (props.product.price * props.quantity) + props.cartTotal)
            return [...props.cart, {prodID: props.product.prodID, name: props.product.name, price: props.product.price, image: props.product.images[0], quantity: props.quantity, size:props.chosenSize, varID: props.chosenSize.id}]; 
             
        }

        let newCart = [...props.cart]
        newCart[index].quantity = parseInt(newCart[index].quantity) + parseInt(props.quantity);
        // Function to update total 
        // props.updateCartTotal( (props.product.price * props.quantity) + props.cartTotal)

        window.sessionStorage.setItem('ophiucus-cart', JSON.stringify(newCart));
        return newCart;
    }

    return (
        <div className ="addtocart--wrapper">
            <button className="button--addtocart" onClick = {() => {props.updateCart(cartCheck()); props.toggleCartOpen(true)}}>
                <div className="button--addtocart__text">ADD TO CART</div>
                <div className="button--addtocart__price">{props.product.price + ' EUR'}</div>
            </button>
        </div>
    );
}

export default AddToCartBTN;