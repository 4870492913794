import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import prodImage from './product1.jpg';
import Swiper from 'swiper/bundle';
// import Swiper styles
import 'swiper/swiper-bundle.css';
import cross from './cross2.png';


function ZoomView(props) {
    const classes = props.isShown ? 'product-zoom-view is-visible' : 'product-zoom-view';
    const bullets = useRef(null);
    const swiperElement = useRef(null);


    const sliderImages =  props.images ? props.images.map((image, i) => <div className="swiper-slide" data-hash={"slide" + (i+1)}><div><img src={image} /></div></div>) : <div className="swiper-slide" data-hash="slide1"><div><img src={prodImage} /></div></div>

    useEffect(() =>{
        const swiper = swiperElement.current.swiper;

        if(swiperElement.current.querySelector('.swiper-slide-active img')){
            swiperElement.current.querySelector('.swiper-slide-active img').addEventListener('click', (e) => {
                if(e.clientX > window.innerWidth/2 ){
                    swiper.slideNext();
                }else{
                    swiper.slidePrev();
                }
            });
        }

    })
    
    return ReactDOM.createPortal(
        <div className={classes}>
            <div className="exit" onClick={() => { props.exitFunc(false);} }>
                <img src={cross} />
                </div>
            { 
                //<img src={props.image} /> 
            }
            <div className="page-product__gallery__content swiper-container" ref={swiperElement} >
                <div className="swiper-wrapper">
                    {
                        //<div className="swiper-slide" data-hash="slide1"><div><img src={prodImage} /></div></div>
                        //<div className="swiper-slide" data-hash="slide2"><div><img src={prodImage} /></div></div>
                        //<div className="swiper-slide" data-hash="slide3"><div><img src={prodImage} /></div></div>
                    }
                    {sliderImages}
                </div>
                <div class="swiper-pagination" ref={bullets}></div>
                <div class="swiper-button-prev">
                </div>
                <div class="swiper-button-next">
                </div>
            </div>
        </div>, document.querySelector('#root')
    );
}

export default ZoomView;