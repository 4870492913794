//import Client from 'shopify-buy';
import Client from 'shopify-buy/index.unoptimized.umd';

    // Initializing a client to return content in the store's primary language
export const client = Client.buildClient({
domain: 'ophiucus.myshopify.com',
storefrontAccessToken: 'e45ba00db8aa39b4304e326fd1166dbd'
});




// Fetch all collections, including their products
client.collection.fetchAllWithProducts().then((collections) => {
  // Do something with the collections
});

// Fetch a single collection by ID, including its products
const collectionId = '263457865898';
// Set a parameter for first x products, defaults to 20 if you don't provide a param

/*
client.collection.fetchWithProducts(collectionId, {productsFirst: 30}).then((collection) => {
  // Do something with the collection
  //console.log(collection);
  //console.log(collection.products);
});
*/

export async function getProducts(){

  //let products = {};
    /*
      // Fetch all products in your shop
      const products = await client.product.fetchAll().then((products) => {
      // Do something with the products
      console.log(products);

      return products;
    
  });
  */
 /*
    client.collection.fetchAllWithProducts().then((collections) => {
      // Do something with the collections
      console.log('collections', collections);
      console.log('collection products', collections[0].products);
    });
    */
    /*
    // Fetch a single collection by ID, including its products
    const collectionId = '263457865898';
    // Set a parameter for first x products, defaults to 20 if you don't provide a param
    */
    const products = await client.collection.fetchWithProducts('Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzI2MzQ1Nzg2NTg5OA==', {productsFirst: 30}).then((collection) => {
      // Do something with the collection
      return collection.products;
    });

  return products;
}

export async function getMetafields(){
  const metafieldQuery = client.graphQLClient.query((root) => {

    root.addConnection('products', { args: { first: 249 } }, (product) => {
      product.add('handle') 
      product.addConnection(
        'metafields',
        { args: { first: 249 } },
        (metafield) => {
          metafield.add('key')
          metafield.add('value')
        }
      ) 
    })
  })
  const metafieldQueryResult = await client.graphQLClient.send(
    metafieldQuery
  )

  console.log('MFQR', metafieldQueryResult);

  return metafieldQueryResult;
}

export async function getJournalImages(){
  const journalQuery = client.graphQLClient.query((root) => {
    root.addConnection('articles', {args: {first:20}}, (article) => {
      article.add('title')
      article.add('handle')
      article.add('url')
      article.add('contentHtml')
      article.addField('image', {}, (image) => {
        image.add('id')
        image.add('originalSrc')
      })
    });
  });

  // Call the send method with the custom products query
  const journalImages = await client.graphQLClient.send(journalQuery).then(({model, data}) => {
    // Do something with the products
    console.log({'model': model, 'data': data})

    return {'model': model, 'data': data};
  });
  return journalImages;
}

export async function getPages(){
  const pageQuery = client.graphQLClient.query((root) => {
    root.addConnection('pages', {args: {first: 10}}, (page) => {
      page.add('title')
      page.add('body') /*
      page.addConnection(
        'metafields',
        { args: { first: 249 } },
        (metafield) => {
          metafield.add('key')
          metafield.add('value')
        }
      ) */
    });
  });

  // Call the send method with the custom products query
  const pages = await client.graphQLClient.send(pageQuery).then(({model, data}) => {
    // Do something with the products

    console.log('modelllll', model)

    return {'model': model};
  });

  return pages;
}


export async function getJournal2(){
  //let journal = await fetch('http://localhost:1337/api/journal-posts?populate=*').then(response => response.json());
  let journal = await fetch('https://ophcms-wuyoe.ondigitalocean.app/api/journal-posts?populate=*',  {headers: new Headers({ Authorization: 'Bearer ' + '57ad6630a3ab47314574920594d2e530d4408a8570c6e97b2af0db6ba7f390fb1caf0695dfe78c6cac2c7d57ac6baafc2d3fa63a2fc249859831a326b83c218924b1a580ffcea42ce07856f6787084d1a115949c31decbe716e35b812529b0a1af4d79b7809d65b95050efb538d3bdd93c65f5cbd8efd3807d3567a3c3d170dd', 'Content-Type': 'application/x-www-form-urlencoded', })}).then(response => response.json());

  return journal;
}

export async function getProducts2(){
  //let products = await fetch('http://localhost:1337/api/products?populate=*').then(response => response.json());
  let products = await fetch('https://ophcms-wuyoe.ondigitalocean.app/api/products?populate=*',  {headers: new Headers({ Authorization: 'Bearer ' + '57ad6630a3ab47314574920594d2e530d4408a8570c6e97b2af0db6ba7f390fb1caf0695dfe78c6cac2c7d57ac6baafc2d3fa63a2fc249859831a326b83c218924b1a580ffcea42ce07856f6787084d1a115949c31decbe716e35b812529b0a1af4d79b7809d65b95050efb538d3bdd93c65f5cbd8efd3807d3567a3c3d170dd', 'Content-Type': 'application/x-www-form-urlencoded', })}).then(response => response.json());

  return products;
}

export async function getProducts3(){
  //let products = await fetch('http://localhost:1337/api/products?populate=*').then(response => response.json());
  let products = await fetch('https://ophcms-wuyoe.ondigitalocean.app/api/products?populate=*',  {headers: new Headers({ Authorization: 'Bearer ' + '57ad6630a3ab47314574920594d2e530d4408a8570c6e97b2af0db6ba7f390fb1caf0695dfe78c6cac2c7d57ac6baafc2d3fa63a2fc249859831a326b83c218924b1a580ffcea42ce07856f6787084d1a115949c31decbe716e35b812529b0a1af4d79b7809d65b95050efb538d3bdd93c65f5cbd8efd3807d3567a3c3d170dd', 'Content-Type': 'application/x-www-form-urlencoded', })}).then(response => response.json());

  return products;
}

export async function getPages1(){
  //let pages = await fetch('http://localhost:1337/api/pages?populate=*').then(response => response.json());
  let pages = await fetch('https://ophcms-wuyoe.ondigitalocean.app/api/pages?populate=*',  {headers: new Headers({ Authorization: 'Bearer ' + '57ad6630a3ab47314574920594d2e530d4408a8570c6e97b2af0db6ba7f390fb1caf0695dfe78c6cac2c7d57ac6baafc2d3fa63a2fc249859831a326b83c218924b1a580ffcea42ce07856f6787084d1a115949c31decbe716e35b812529b0a1af4d79b7809d65b95050efb538d3bdd93c65f5cbd8efd3807d3567a3c3d170dd', 'Content-Type': 'application/x-www-form-urlencoded', })}).then(response => response.json());

  return pages;
}

export async function getInfoPage(){
  //let data = await fetch('http://localhost:1337/api/pages/5?populate=*').then(response => response.json());
  let data = await fetch('https://ophcms-wuyoe.ondigitalocean.app/api/pages/2?populate=*',  {headers: new Headers({ Authorization: 'Bearer ' + '57ad6630a3ab47314574920594d2e530d4408a8570c6e97b2af0db6ba7f390fb1caf0695dfe78c6cac2c7d57ac6baafc2d3fa63a2fc249859831a326b83c218924b1a580ffcea42ce07856f6787084d1a115949c31decbe716e35b812529b0a1af4d79b7809d65b95050efb538d3bdd93c65f5cbd8efd3807d3567a3c3d170dd', 'Content-Type': 'application/x-www-form-urlencoded', })}).then(response => response.json());

  return data;
}

export async function getSiteSettings(){
  //let data = await fetch('http://localhost:1337/api/site-setting?populate=*').then(response => response.json());
  let data = await fetch('https://ophcms-wuyoe.ondigitalocean.app/api/site-setting?populate=*',  {headers: new Headers({ Authorization: 'Bearer ' + '57ad6630a3ab47314574920594d2e530d4408a8570c6e97b2af0db6ba7f390fb1caf0695dfe78c6cac2c7d57ac6baafc2d3fa63a2fc249859831a326b83c218924b1a580ffcea42ce07856f6787084d1a115949c31decbe716e35b812529b0a1af4d79b7809d65b95050efb538d3bdd93c65f5cbd8efd3807d3567a3c3d170dd', 'Content-Type': 'application/x-www-form-urlencoded', })}).then(response => response.json());

  return data;
}


export async function getHero(){
  //let data = await fetch('http://localhost:1337/api/hero?populate=*').then(response => response.json());
  let data = await fetch('https://ophcms-wuyoe.ondigitalocean.app/api/hero?populate=*',  {headers: new Headers({ Authorization: 'Bearer ' + '57ad6630a3ab47314574920594d2e530d4408a8570c6e97b2af0db6ba7f390fb1caf0695dfe78c6cac2c7d57ac6baafc2d3fa63a2fc249859831a326b83c218924b1a580ffcea42ce07856f6787084d1a115949c31decbe716e35b812529b0a1af4d79b7809d65b95050efb538d3bdd93c65f5cbd8efd3807d3567a3c3d170dd', 'Content-Type': 'application/x-www-form-urlencoded', })}).then(response => response.json());
  
  return data;
}

