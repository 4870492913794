import React from 'react';
import { Link } from 'react-router-dom';

function CookieBar(props) {
    const hidden = props.cookieAccepted ? 'footer-sticky-bar-wrapper hidden' : 'footer-sticky-bar-wrapper';

    return (
        <div className={hidden}>
            <div className="footer-sticky-bar" >
                <div>We use cookies to enhance your experience.  By continuing to use this site, you agree to our use of cookies. <Link to="/terms"><span className="footer-sticky-bar__link"> More Info</span></Link></div>
                <div className="footer-sticky-bar__btn" onClick = {() => { props.cookieAgree(true)}}>AGREE</div>
            </div>
        </div>
    );
}

export default CookieBar;