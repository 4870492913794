import React, { useEffect, useRef, useState } from 'react';
import ProductCard from '../ProductCard';
import productImage from './PRODUCT_1.jpg';
import wideProductImage from './PRODUCT_DOUBLE.jpg';
import gsap from 'gsap';
import { useParams, useLocation } from "react-router-dom";
import { Transition } from 'react-transition-group';

function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  
function ProductListing(props) {
    const [productsArray, setProductArray] = useState([]);
    
    let productsArrayTemp = [];
    //let allProducts = props.productList;
    let data = props.appData;
    let allProducts = props.productList;
    
    useEffect(() =>
        {
            console.log('prodlist', props.productList)
            Object.keys(allProducts).forEach((key) => {
                productsArrayTemp.push(
                    {
                        name: allProducts[key].title,
                        price: allProducts[key].variants[0].price, 
                        image: allProducts[key].images[0].src, 
                        thumb1: allProducts[key]?.thumbnails?.thumbnail_link_portrait || allProducts[key].images[0].src,
                        thumb2: allProducts[key]?.thumbnails?.thumbnail_link_landscape || allProducts[key].images[0].src,
                        thumb3: allProducts[key]?.thumbnails?.thumbnail_link_portrait || allProducts[key].images[0].src,
                        type: allProducts[key].product_type, 
                        description: allProducts[key].body_html, 
                        gridspan: 1, 
                        key: allProducts[key].id 
                    }
                )
            })
            setProductArray(productsArrayTemp);
        }
    ,[allProducts])
    
    //const allowedProdTypes =['earrings','necklaces'];

    let id = useParams();
    let query = useQuery();
    const prodType = query.get('type')?.toLowerCase();
    const listingWrap = useRef();
    let myClass;

    let products;

    //if(prodType != null && allowedProdTypes.find((type) => type === prodType)){
    if(prodType != null){
        props.updateSlashDisplay('/' + prodType);
        products = productsArray.map((product) => {   
            console.log(product);
            if(product.type.toLowerCase() == prodType){
                return <Transition key={'z' + product.key} in={true} timeout={500} unmountOnExit><ProductCard onLoad={ () => { fixBoxHeights(); } } introAnimation={props.introAnimation} name={product.name} price={product.price} image={product.thumb1} description={product.description} gridspan={product.gridspan} key={product.key}></ProductCard></Transition>;
            }else{
                return <Transition key={'z' + product.key} in={false} timeout={500} unmountOnExit><ProductCard onLoad={ () => { fixBoxHeights(); } } introAnimation={props.introAnimation} name={product.name} price={product.price} image={product.thumb1} description={product.description} gridspan={product.gridspan} key={product.key}></ProductCard></Transition>;
            }
        })
    }else{ 
        products = productsArray.map((product, i) => {    
            if( (i+1)%4 === 0 && (i+1)%8 !== 0 ){
                return <Transition key={'z' + product.key} in={prodType == null} timeout={500} unmountOnExit><ProductCard onLoad={ () => { fixBoxHeights(); } } introAnimation={props.introAnimation} name={product.name} price={product.price} image={product.thumb2} description={product.description} gridspan={product.gridspan} key={product.key}></ProductCard></Transition>;
            }
            else if( (i+1)%7 === 0){
                return <Transition key={'z' + product.key} in={prodType == null} timeout={500} unmountOnExit><ProductCard onLoad={ () => { fixBoxHeights(); } } introAnimation={props.introAnimation} name={product.name} price={product.price} image={product.thumb3} description={product.description} gridspan={product.gridspan} key={product.key}></ProductCard></Transition>;
            }
            else{
                return <Transition key={'z' + product.key} in={prodType == null} timeout={500} unmountOnExit><ProductCard onLoad={ () => { fixBoxHeights(); } } introAnimation={props.introAnimation} name={product.name} price={product.price} image={product.thumb1} description={product.description} gridspan={product.gridspan} key={product.key}></ProductCard></Transition>;
            }
        })
    }
    
    function introAnim(){
        console.log('introing')
    }

    function exitAnim(){
        console.log(listingWrap);
        gsap.fromTo(listingWrap.current.querySelectorAll('.product-card'), {y:0, opacity:1}, {y:10, stagger: 0.4, opacity:0});
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        myClass = '.' + listingWrap.current.getAttribute('class');

        window.addEventListener('resize', function(){
            if(document.querySelector('.product-card__imagewrapper img')){
                fixBoxHeights();
            }
        })

    }, [])

    useEffect(() => {
        animations(props.transState);
    },[props.transState])

    function animations(type){
        if(type=='entering'){
            introAnim();
        }
        if(type=='exiting'){
            console.log('exiting')
            exitAnim();
        }
    }

    function fixBoxHeights(){
        let firstBox = document.querySelector('.product-card');
        let boxHeight = document.querySelector('.product-card__imagewrapper img').offsetHeight;
        let allCards = document.querySelectorAll('.product-card:not(:first-of-type) .product-card__imagewrapper img');
        let seventhCards = document.querySelectorAll('.product-card:not(:first-of-type):nth-child(7n) .product-card__imagewrapper img');
        let computedStyle = window.getComputedStyle(firstBox);

        [...allCards].forEach((card) => {
            card.style.height = boxHeight + 'px'; 
        });

        [...seventhCards].forEach((card) => {
            card.style.height = (firstBox.offsetHeight + boxHeight + parseInt(computedStyle.getPropertyValue('margin-bottom'))) + 'px'; 
        });

        console.log('computedStyle');
    }

    useEffect(() => {
        
    },[]);

    
    return (
        <div ref={listingWrap} className='product-listing-wrapper'>
            <div className='product-listing'>
               {products}
            </div>
        </div>
    );
}

export default ProductListing;