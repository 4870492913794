import React, { useRef ,useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import AddToCartBTN from '../../AddToCartBTN'
import ProductSlider from './ProductSlider';
import Dropdown from '../../Dropdown';
import gsap from 'gsap';
import prodImage from './product1.jpg';
import { useParams } from 'react-router-dom';

function ProductPage(props) {
    const [productData, setProductData] = useState({});
    const productImage = useRef(null);
    const productDetails = useRef(null);
    const [basketQuantity, updateBasketQuantity] = useState(1);
    const [chosenSize, updateChosenSize] = useState(3);
    const slug = useParams().slug;

    function getNameFromSlug(slug){
        let str = slug.split('-');

        for (var i = 0; i < str.length; i++) {
            str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1); 
        }

        console.log('bouba', str.join(' '));

        return str.join(' ');
    }

    function getSizeObjfromSizeTitle(sizeTitle){
        console.log('prodcheck', product);
        for(let i = 0; i < product.sizes.length; i++){
            if(product.sizes[i].title === sizeTitle){
                return product.sizes[i];
            }
        }
    }

    function chooseSize(sizeTitle){
        const sizeObj = getSizeObjfromSizeTitle(sizeTitle);
        console.log('sizeObj', sizeObj)
        updateChosenSize(sizeObj)
    }

    

    useEffect(() => {
        console.log('hmmm', props.products);
        /*
        Removed after slug technique changed

        if(props?.products && props?.products[getNameFromSlug(slug)]){
            console.log('dark', props?.products);
            setProductData(props.products[getNameFromSlug(slug)])
        }
        */

        if(props?.products && props?.products[slug]){
            console.log('dark', props?.products);
            setProductData(props.products[slug])
        }
    }, [props.products])

    let prodImages;
    let prodSizes;

    if(productData.images){
        prodImages = productData.images.map((image) => {
            return image.src;
        });
    }

    if(productData.variants){
        prodSizes = productData.variants.map((variant) => {
            return {title: variant.title, id:variant.id};
        });
    }else{
        prodSizes = null;
    }

    // With the slug, we can select the product from the provided products array  by using const product = products[slug]  and assigning to the product array below
    
    // Example Representation: {'dummyProduct': { prodID: '12345', name: 'Earrings', images:['http://localhost:3001/static/media/product1.956436ce.jpg'], price:'£1.000', description: 'Solid Yellow Gold, Australian Opal, Ruby, Diamonds and Pendant', sizes:[1,2,3,4] }}
    //const product = { prodID: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDIxMzgxMjE4MzIxMA==', name: 'Earrings I', type: 'Earrings', images:[prodImage], price:'1000', description: 'Solid Yellow Gold, Australian Opal, Ruby, Diamonds and Pendant', sizes:[1,2,3,4,5] }
    

    let product;

    if(productData?.variants){
        product = { prodID: productData.id, name: productData.title, type: productData.product_type, images:prodImages, price: productData.variants[0].price, description: productData.body_html, sizes:prodSizes }
    }
    else{
        product = { prodID: productData.id, name: productData.title, type: productData.product_type, images:prodImages, price: productData.price, description: productData.body_html, sizes:prodSizes }
    }

    

    //console.log(prodSizes)

    //console.log('product data',productData)

    //console.log('product', product);
    
    
    function introAnim(){
        //gsap.from(animatedDropdowns, {y:10, stagger:0.2, opacity:0})
        props.introAnimation.fromTo(productImage.current, {y:10, opacity:0}, {y:0, stagger:0.2, opacity:1});
        props.introAnimation.fromTo(productDetails.current, {opacity:0}, {stagger:0.2, opacity:1});
    }

    function exitAnim(){
        gsap.fromTo(productImage.current, {y:0, stagger:0.2, opacity:1}, {y:10, opacity:0});
        gsap.fromTo(productDetails.current, {opacity:1}, {stagger:0.2, opacity:0});
    }

    useEffect(() => {
        animations(props.transState);
    },[props.transState])

    function animations(type){
        if(type=='entered'){
            introAnim();
        }
        if(type=='exiting'){
            exitAnim();
        }
    }
    //console.log('sizes', product.sizes)
        return (
            <div className="page" onLoad={() => {if(product.sizes != null){ chooseSize(product.sizes[0].title)};//
            console.log('prodprod', product);
        }}>
                <div className="page-product">
                    <div className="page-product__gallery" ref={productImage}>
                        <ProductSlider introAnimation={props.introAnimation} images={product.images}></ProductSlider>
                    </div>
                    <div className="page-product__details">
                        <div className="page-product__details__content">
                            <div ref={productDetails}>
                                <div className="product__title">
                                    <p>{product.name}</p>
                                </div>
                                <div className="product__description">
                                    <p>DESCRIPTION</p>
                                    <p>{product.description}</p>
                                </div>

                                {
                                    productData?.variants?.length > 1 &&
                                
                                    <div className="product__size">
                                        <p>
                                            <span>SIZE</span>
                                            <Dropdown updateCorrValue ={chooseSize} values={product.sizes} ></Dropdown>
                                        </p>
                                    </div>
                                }
                                <div className="product__quantity">
                                    <p>
                                        <span>QUANTITY</span>
                                        <Dropdown updateCorrValue ={updateBasketQuantity} values={[1,2,3,4,5,6,7,8,9,10]}></Dropdown>
                                    </p>
                                </div>
                                
                                
                                <AddToCartBTN toggleCartOpen={props.toggleCartOpen} cart ={props.cart} product={product} quantity={basketQuantity} updateCart={props.updateCart} chosenSize={chosenSize}></AddToCartBTN>
                                <Link to='/sizing-guide'><p class="underlined-link">Sizing Guide</p></Link>
                                <Link to='/shipping'><p class="underlined-link">Delivery and Returns</p></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

}

export default ProductPage;