import React from 'react';

function MenuBurger(props) {
    const classes = props.isMenuOpen ? 'hamburger hamburger--collapse is-active' : 'hamburger hamburger--collapse';

    return (
        <button onClick={() => {props.menuClickFunc(!props.isMenuOpen)}} className={classes} type="button">
            <span class="hamburger-box">
                <span class="hamburger-inner"></span>
            </span>
        </button>
    );
}

export default MenuBurger;