import React, { useRef ,useEffect, useState } from 'react';
//import information from './INFORMATION.jpg';
import { Link } from 'react-router-dom';
import { getInfoPage} from '../../../modules/store';
import gsap from 'gsap';

function InformationPage(props) {
    const pageInformation = useRef(null);
    let additonalLinks;
    let additonalLinksMobile;
    const [menuLinks , setMenuLinks] = useState({}); 

    if(Object.keys(props.pageData).length !== 0){
        additonalLinks = props.pageData?.menu_item.map((menuLink) => {
            return <a key={menuLink.id} href={menuLink.link_url}>{menuLink.link_name}</a>;
        })

        additonalLinksMobile = props.pageData?.menu_item.map((menuLink) => {
            return <p key={menuLink.id}><a href={menuLink.link_url}>{menuLink.link_name}</a></p>;
        })
        console.log(props.pageData);
    }
    
    function introAnim(){
        const pageInformationCols = pageInformation.current.children;

        props.introAnimation.fromTo(pageInformationCols, {y:10, stagger:0.2, opacity:0}, {y:0, stagger:0.2, opacity:1})
    }

    function exitAnim(){
        const pageInformationCols = pageInformation.current.children;
        gsap.fromTo(pageInformationCols, {y:0, opacity:1}, {y:10, stagger:0.2, opacity:0})
    }

    useEffect(() => {
        animations(props.transState);
    },[props.transState])

    function animations(type){
        if(type=='entered'){
            introAnim();
        }
        if(type=='exiting'){
            exitAnim();
        }
    }

    return (
        <div className="page">
            <div className="page-information" ref={pageInformation}>
                <div className="image">
                    <img src={props.pageData.featured_image_link} />
                </div>
                <div>
                    <p dangerouslySetInnerHTML={{ __html: props.children }}>
                    </p>
                    
                </div>
                <div>
                    <div>
                        <p>
                            CONTACT <br />
                            {props.siteSettings.site_email}
                        </p>
                    </div>
                    <div>
                        <nav>SUBSCRIBE</nav>
                        <div id="mc_embed_signup" class="mc_embed_signup">
                            <form action="https://ophiuchusjewels.us21.list-manage.com/subscribe/post?u=a6b29ec03fb8010d32c0cf54d&amp;id=4d346c62b4&amp;f_id=003dcae1f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_self">
                                <div id="mc_embed_signup_scroll">
                                
                                    <div class="mc-field-group">
                                        <input type="email" name="EMAIL" class="required email" id="mce-EMAIL"  placeholder="Enter your email address" required />
                                        <span id="mce-EMAIL-HELPERTEXT" class="helper_text"></span> 
                                    </div>
                                    <div id="mce-responses" class="clear foot">
                                        <div class="response" id="mce-error-response" style={{display:'none'}}></div>
                                        <div class="response" id="mce-success-response" style={{display:'none'}}></div>
                                    </div>    
                                    <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true"><input type="text" name="b_a6b29ec03fb8010d32c0cf54d_4d346c62b4" tabIndex="-1" /></div>
                                    <div class="optionalParent">
                                        <div class="clear foot">
                                            <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button" />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>


                    </div>
                    <div className="bottom-of-bars-mobile">
                        <br />
                        {additonalLinksMobile}
                        <p>
                            <Link to="/sizing-guide">SIZING GUIDE</Link>
                        </p>
                        <p>
                            <Link to="/shipping">DELIVERY & RETURNS</Link>
                        </p>
                        <p>
                            <Link to="/privacy">PRIVACY POLICY</Link>
                        </p>
                        <p>
                            <Link to="/terms">TERMS</Link>
                        </p>
                    </div>
                </div>
                <div>
                    <div className="bottom-of-bars">
                        <nav>{additonalLinks}<Link to="/sizing-guide">SIZING GUIDE</Link><Link to="/shipping">DELIVERY & RETURNS</Link><Link to="/privacy">PRIVACY POLICY</Link><Link to="/terms">TERMS</Link></nav>
                        <p><span>k</span></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InformationPage;