import React, { useState } from 'react';
import CartItem from './CartItem';
import CheckoutBtn from './CheckoutBtn';
import cross from './cross2.png';

function CartBar(props) {
    const cartClass = props.isCartOpen ? 'open' : '';

    console.log(props.cart);

    // Calculate Total
    if(props.cart != []){
        props.updateCartTotal(props.cart.reduce((acc, item) => acc + (parseInt(item.price) * parseInt(item.quantity)),0));
    }

    const cartItems = props.cart.length > 0 ? props.cart.map((item) => {return <CartItem item={item} cart={props.cart} updateCart={props.updateCart}></CartItem> }) : 'Your cart is currently empty';

    console.log('cart', props.cart);
    return (
        <div className={`cart-bar ${cartClass}`}>
            <a className="cart-bar__close-btn" onClick={() => {props.toggleCartOpen(!props.isCartOpen) }}><img src={cross} /></a>
            <div className="cart-bar__contentwrapper">
                <div className="cart-bar__items">
                    {cartItems}

                    {props.cart.length > 0  &&
                        <table className="cart-bar__table">
                            <tr>
                                <td>CART</td>
                            </tr>
                            <tr>
                            <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>Shipping</td>
                                <td><div>Free</div></td>
                            </tr>
                            <tr className="total-row">
                                <td>Total</td>
                                <td><div>{props.cartTotal + ' EUR'}</div></td>
                            </tr>
                        </table>
                    }
                </div>
                <div className="cart-bar__details">
                    
                    <CheckoutBtn cart={props.cart}></CheckoutBtn>
                </div>
            </div>
        </div>
    );
}

export default CartBar;