import React from 'react';
import { BrowserRouter, Link } from 'react-router-dom';

function Menu(props) {
    const classes = props.isMenuOpen ? 'menu overlay overlay--menu is-open' : 'menu overlay overlay--menu';
    let prodTypeLinks;
    let additonalLinks;
    let additonalLinksMobile;

    if(Object.keys(props.pageData).length !== 0){
        additonalLinks = props.pageData?.menu_item.map((menuLink) => {
            if(menuLink.link_url.indexOf('http://') == -1 && menuLink.link_url.indexOf('https://') == -1){
                return <Link to={menuLink.link_url} onClick={() => { props.toggleMenuOpen() }}>{menuLink.link_name}</Link>
            }
            
            return <a href={menuLink.link_url} >{menuLink.link_name}</a>
        })
/*
        additonalLinksMobile = props.pageData?.menu_item.map((menuLink) => {
            return <p key={menuLink.id}><a href={menuLink.link_url}>{menuLink.link_name}</a></p>;
        })
        console.log(props.pageData);
        */
    }

    if(props.productTypes.map){
        prodTypeLinks = props.productTypes.map((type) => {
            return <Link to={`/shop?type=${type.toLowerCase()}`} onClick={() => { props.toggleMenuOpen() }}>{type.toUpperCase()}</Link>
        })
    }

    return (
        <div className={classes}>
            <div className="menu-wrapper">
                <div className="menu1"> 
                    <nav className="">
                        <Link onClick={() => { props.toggleMenuOpen() }} to="/shop"><span className="underlined-anim">SHOP</span></Link>
                        {prodTypeLinks}
                    </nav>
                    <nav>
                        <Link to="/journal" onClick={() => { props.toggleMenuOpen() }}><span className="underlined-anim">JOURNAL</span></Link>
                        <Link to="/information" onClick={() => { props.toggleMenuOpen() }}><span className="underlined-anim">INFORMATION</span></Link>
                    </nav>
                    <nav>         
                    </nav>
                </div>
                    
                <nav className="menu2">
                    <Link to="/sizing-guide" onClick={() => { props.toggleMenuOpen() }}>SIZING GUIDE</Link>
                    <Link to="/shipping" onClick={() => { props.toggleMenuOpen() }}>DELIVERY & RETURNS</Link>
                    <Link to="/privacy" onClick={() => { props.toggleMenuOpen() }}>PRIVACY POLICY</Link>
                    <Link to="/terms" onClick={() => { props.toggleMenuOpen() }}>TERMS</Link>
                    {additonalLinks}
                    <div>
                        <p>SUBSCRIBE</p>
                        <div id="mc_embed_signup">
                            <form action="https://ophiuchusjewels.us21.list-manage.com/subscribe/post?u=a6b29ec03fb8010d32c0cf54d&amp;id=4d346c62b4&amp;f_id=003dcae1f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_self">
                                <div id="mc_embed_signup_scroll">
                                
                                    <div class="mc-field-group">
                                        <input type="email" name="EMAIL" class="required email" id="mce-EMAIL"  placeholder="Enter your email address" required />
                                        <span id="mce-EMAIL-HELPERTEXT" class="helper_text"></span> 
                                    </div>
                                    <div id="mce-responses" class="clear foot">
                                        <div class="response" id="mce-error-response" style={{display:'none'}}></div>
                                        <div class="response" id="mce-success-response" style={{display:'none'}}></div>
                                    </div>    
                                    <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true"><input type="text" name="b_a6b29ec03fb8010d32c0cf54d_4d346c62b4" tabIndex="-1" /></div>
                                    <div class="optionalParent">
                                        <div class="clear foot">
                                            <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button" />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </nav>
            </div>
            
        </div>
    );
}

export default Menu;