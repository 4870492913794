import './App.scss';
import React, { useEffect, useState } from 'react';
import { Switch, BrowserRouter, Route, useLocation, useParams } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';
import HomePage from './components/Pages/HomePage';
import ProductPage from './components/Pages/ProductPage';
import InformationPage from './components/Pages/InformationPage'
import HeroHeader from './components/HeroHeader';
import Toolbar from './components/Toolbar';
import ProductListing from './components/ProductListing';
import CartBar from './components/CartBar';
import JournalPage from './components/Pages/JournalPage';
import Menu from './components/Menu';
import gsap from 'gsap';
import WrittenContent from './components/WrittenContent';
import PrivacyPolicy from './components/WrittenContent/PrivacyPolicy';
import Terms from './components/WrittenContent/Terms';
import Shipping from './components/WrittenContent/Shipping';
import SizingGuide from './components/WrittenContent/SizingGuide';
import CookieBar from './components/CookieBar';
//import { getJournalImages, getPages, getProducts, getMetafields } from './modules/store';
import { getJournal2, getPages1, getProducts3, getInfoPage, getSiteSettings, getHero } from './modules/store';
import { find, uniq, compact, split } from 'lodash';
import appData from './fixtures/fixtures.json';

function App() {
  const [cartOpen , toggleCartOpen] = useState(false); 
  const [menuOpen , toggleMenuOpen] = useState(false); 
  const [slashDisplay , updateSlashDisplay] = useState(''); 
  const [inProp, setInProp] = useState(true);
  const [isLoading, updateLoading] = useState(false);
  // Maybe put local in

  // Cart should contain {product, quantity}
  let preExistingCart = [];

  if(JSON.parse(window.sessionStorage.getItem('ophiucus-cart'))){
    //preExistingCart = window.sessionStorage.getItem('ophiucus-cart');
    console.log('sesssion cart')
    preExistingCart = JSON.parse(window.sessionStorage.getItem('ophiucus-cart'));

    console.log(preExistingCart);
  }
  
  const [cart, updateCart] = useState(preExistingCart);
  const [cartTotal, updateCartTotal] = useState(0);
  const [cookieAccepted, toggleCookieAccepted] = useState(false);
  const [data, setData] = useState({});
  const [products, setProducts] = useState({});
  const [productTypes, setProductTypes] = useState({});
  const [pages, setPages] = useState({});
  const [images, setImages] = useState({});
  const [infoPageData, setInfoPageData] = useState({});
  const [siteSettingsData, setSiteSettingsData] = useState({});
  const [heroData, setHeroData] = useState({});
  const introAnimation = gsap.timeline();
  const nodeRef = React.useRef(null)
  const location = useLocation();
  
  useEffect(() =>{
    //introAnimation();
    /*
    const pagesRequested = getPages().then(response => {
      console.log('pagessssss', response)
      let result = response.model.pages;
      let page = {};
      result.map((res) =>{
        page[res.title] = res.body;
      })

      let tempObj = data;

      tempObj.pages = page;
      console.log('tempob', tempObj);
      
      setData(tempObj);
      setPages(page);

      updateLoading(false)
      //updateLoading(false)
      return response.model.pages
    
    }); 
    
    const journalImagesRequested =  getJournalImages().then(response => {

      let result = response.data.articles.edges;
      let images = {};
      result.map((res) =>{
        images[res.node.title] = res.node.image;
      })

      let tempObj = data;

      tempObj.images = images;

      setData(tempObj);
      setImages(images);

      console.log('isLoading', isLoading)

      updateLoading(false)

      console.log('isLoading', isLoading)
    }); 

    const productsRequested =  getProducts().then((response) => {
      let metaFieldsResults = getMetafields().then(response2 => {

        let products = {};

        let metafieldResponse = response2.model.products.map((res, i) =>{
          return res;
        });
        
        response.map((res, i) =>{
          console.log(res);
          products[res.title] = res;
          products[res.title].metafields = find(response2.model.products, { 'id' : products[res.title].id}).metafields ;
        }) 

        let tempObj = data;

        tempObj.products = products;

        setData(tempObj);
        setProducts(products);

        let prodTypes = Object.keys(products).map((key, index) => {
          return products[key].productType;
        })

        prodTypes = compact(uniq(prodTypes));

        setProductTypes(prodTypes);
      
        updateLoading(false)
        return response2
      });

    });

    */

    let images2 = getJournal2();

    images2.then((response) => {
      console.log(response);
      let imageObj = {images: {}};

      response.data.forEach((data) => {
        let journal_text = data.attributes.image_text[0].Text;
        let journal_image = data.attributes.image_text[0].image_url;
        let journal_id = data.attributes.image_text[0].id;

        imageObj['images'][journal_text] = {id: journal_id, originalSrc: journal_image};  
      })

      setImages(imageObj.images);

      //console.log(imageObj.images);
    });

    let products2 = getProducts3();

    products2.then((response) => {
      console.log('rrr', response.meta.productList);
      console.log('ddd', appData.products);

      for(const prod in response.meta.productList){
        if(response.meta.productList[prod].status !== 'active'){
          delete response.meta.productList[prod];
          continue;
        }
        const old_key = prod;

        const new_key = prod.toLowerCase().split(' ').join('-');

        if (old_key !== new_key) {
          
          Object.defineProperty(response.meta.productList, new_key,
              Object.getOwnPropertyDescriptor(response.meta.productList, old_key));
          delete response.meta.productList[old_key];
        }

      }

      console.log('newprod', response.meta.productList);

      setProducts(response.meta.productList);



      let prodTypes = Object.keys(response.meta.productList).map((key, index) => {
        return response.meta.productList[key].product_type;
      })

      prodTypes = compact(uniq(prodTypes));

      console.log('prodtypes', prodTypes)

      setProductTypes(prodTypes);

    })

    let pages2 = getPages1();

    pages2.then((response) => {

      //setPages(response.meta.productList);
      response.data.forEach((page)=> {
        pages[page.attributes.Title] = page.attributes.Description;
      })

      console.log('fran', pages);

    })

    let informationPage =  getInfoPage();

    informationPage.then((response) => {
        setInfoPageData(response.data.attributes);
    })

    let siteSettings =  getSiteSettings();

    siteSettings.then((response) => {
        setSiteSettingsData(response.data.attributes);
    })

    let hero =  getHero();

    hero.then((response) => {
        setHeroData(response.data.attributes);
    })


    //console.log('prods', getProducts3());

    //setImages(appData.images);
    //setPages(appData.pages);
    //setProducts(appData.products);
    //updateLoading(false)
  }, [])

  if(Object.keys(data).length === 0 && Object.getPrototypeOf(data) === Object.prototype && false){
  //if(isLoading || data=={}){
    return (
      <div className="App">
        <p>loading</p>
      </div>
    )
  }else{
    return (
      <div className="App">
          <Toolbar setInProp={setInProp} productTypes={productTypes} cart={cart} isCartOpen={cartOpen} toggleCartOpen={toggleCartOpen} isMenuOpen={menuOpen} toggleMenuOpen={toggleMenuOpen} introAnimation={introAnimation} slashDisplay={slashDisplay} updateSlashDisplay={updateSlashDisplay}></Toolbar>
          <CartBar isCartOpen={cartOpen} toggleCartOpen={toggleCartOpen} cart={cart} updateCart={updateCart} cartTotal={cartTotal}  updateCartTotal={updateCartTotal}></CartBar>
          <CookieBar cookieAccepted={cookieAccepted} cookieAgree={toggleCookieAccepted}></CookieBar>
          <Menu pageData={infoPageData} productTypes={productTypes} isMenuOpen={menuOpen} toggleMenuOpen={toggleMenuOpen}></Menu>
    
          <TransitionGroup childFactory={child => React.cloneElement(child)}>
            <Route exact path='/' key="/">
              {({ match }) => {  console.log('Home Match: ' + match); return (
                <Transition key="A" in={match!=null} timeout={2000} unmountOnExit>
                    {state => { console.log('Home State: ' + state); return (
                      <div className='page-holder'>
                        <HomePage appData= {data} productList={products} introAnimation={introAnimation} transState={state} updateSlashDisplay={updateSlashDisplay} hero={heroData}></HomePage>
                      </div>
                    )}}
                </Transition>
              )}}
            </Route>

            <Route exact path='/journal' key="/journal">
              {({ match }) => { console.log('Journal Match: ' + match); return (
                <Transition key="B" in={match!=null} timeout={2000} unmountOnExit>         
                  {state => { console.log('Journal State: ' + state); return (
                    <div className='page-holder'>
                      <JournalPage imageList={images}  transState={state}></JournalPage>
                    </div>
                  )}}
                </Transition>
              )}}
            </Route>

            <Route exact path='/information' key="/information">
              {({ match }) => { return (
                <Transition key="C" in={match!=null} timeout={2000} unmountOnExit>            
                      {state => { return (
                        <div className='page-holder'>
                          <InformationPage transState={state} introAnimation={introAnimation} pageData={infoPageData} siteSettings={siteSettingsData}>
                            {
                              pages["Information"]
                            }
                          </InformationPage>
                        </div>
                      )}}
                </Transition>
              )}}
            </Route>

            <Route exact path='/privacy' key="/privacy">
              {({ match }) => { return (
                <Transition key="D" in={match!=null} timeout={2000} unmountOnExit>            
                  {state => { return (
                    <div className='page-holder'>
                      <WrittenContent transState={state}>
                        <PrivacyPolicy>
                          {
                            pages["Privacy Policy"]
                          }
                        </PrivacyPolicy>
                      </WrittenContent>
                    </div>
                  )}}
                </Transition>
              )}}
            </Route>

            <Route exact path='/terms' key="/terms">
              {({ match }) => { return (
                <Transition key="E" in={match!=null} timeout={2000} unmountOnExit>            
                  {state => { return (
                    <div className='page-holder'>
                      <WrittenContent transState={state}>
                        <Terms>
                          {
                            pages["Terms"]
                          }
                        </Terms>
                      </WrittenContent>
                    </div>
                  )}}
                </Transition>
              )}}
            </Route>

            <Route exact path='/shipping' key="/shipping">
              {({ match }) => { return (
                <Transition key="F" in={match!=null} timeout={2000} unmountOnExit>            
                  {state => { return (
                    <div className='page-holder'>
                      <WrittenContent transState={state}>
                        <Shipping>
                          {
                            pages["Delivery"]
                          }
                        </Shipping>
                      </WrittenContent>
                    </div>
                  )}}
                </Transition>
              )}}
            </Route>

            <Route exact path='/sizing-guide' key="/sizing-guide">
              {({ match }) => { return (
                <Transition key="G" in={match!=null} timeout={2000} unmountOnExit>            
                  {state => { return (
                    <div className='page-holder'>
                      <WrittenContent transState={state}>
                        <SizingGuide>
                          {
                            pages["Size Guide"]
                          }
                        </SizingGuide>
                      </WrittenContent>
                    </div>
                  )}}
                </Transition>
              )}}
            </Route>

            <Route exact path='/shop' key="/shop">
              {({ match }) => { return (
                <Transition key="H" in={match!=null} timeout={2000} unmountOnExit>            
                  {state => { return (
                    <div className='page-holder'>
                      <ProductListing productList = {products} transState={state} introAnimation={introAnimation} updateSlashDisplay={updateSlashDisplay}  ></ProductListing>
                    </div>
                  )}}
                </Transition>
              )}}
            </Route>

            <Route exact path='/shop?type=:typename' key="/shop?type=:typename">
              {({ match }) => { return (
                <Transition key="I" in={match!=null} timeout={2000} unmountOnExit>            
                  {state => { return (
                    <div className='page-holder'>
                      <ProductListing updateSlashDisplay={updateSlashDisplay}  transState={state} productSection={true} hello='hello' introAnimation={introAnimation} key="shoppage"></ProductListing>
                    </div>
                  )}}
                </Transition>
              )}}
            </Route>

            <Route exact path='/shop/:slug' key="/shop/:slug">
              {({ match }) => { return (
                <Transition key="K" in={match!=null} timeout={2000} unmountOnExit>            
                  {state => { return (
                    <div className='page-holder'>
                      <ProductPage products={products} transState={state} introAnimation={introAnimation} cart={cart} toggleCartOpen={toggleCartOpen} updateCart={updateCart}></ProductPage>
                    </div>
                  )}}
                </Transition>
              )}}
            </Route>

          </TransitionGroup>
      </div>
    );
  }
}

export default App;