import React, { useEffect, useLayoutEffect } from 'react';
import 'interactjs';
import { draggable } from '../../../modules/draggable.js';
import gsap from 'gsap';
import image1 from './img/09-marina-gallo-ophiuchus-jewels-paine-chile-travel-diary.jpg'
import image2 from './img/12-marina-gallo-ophiuchus-jewels-paine-chile-travel-diary.jpg'
import image3 from './img/14-marina-gallo-ophiuchus-jewels-paine-chile-travel-diary.jpg'
import image4 from './img/20-marina-gallo-ophiuchus-jewels-paine-chile-travel-diary.jpg'
import image5 from './img/25-marina-gallo-ophiuchus-jewels-paine-chile-travel-diary.jpg'
import image6 from './img/27-marina-gallo-ophiuchus-jewels-paine-chile-travel-diary.jpg'
import image7 from './img/849ae2509d476fdb59b17fe3270ac762.jpg'
import image8 from './img/e68c0ce3cd1a74764aafd456b277bee9.jpg'
import image9 from './img/screenshot1.png'
import image10 from './img/Screenshot2.png'
import { isMobile } from 'react-device-detect';

/*
 const images =[
    {src: image1, description: 'description1', key: 1},
    {src: image2, description: 'description2', key: 2},
    {src: image3, description: 'description3', key: 3},
    {src: image4, description: 'description4', key: 4},
    {src: image5, description: 'description1', key: 5},
    {src: image6, description: 'description2', key: 6},
    {src: image7, description: 'description3', key: 7},
    {src: image8, description: 'description4', key: 8},
    {src: image9, description: 'description3', key: 9},
    {src: image10, description: 'description4', key: 10}
]
*/

const fadeBoxTL = gsap.timeline();
const boxClasses = isMobile ? 'dragbox': 'dragbox draggable' ;
const pageClasses = isMobile ? 'page journal-mobile': 'page fullscreen' ;


function JournalPage(props) {
    console.log(props.imageList)

    //const images = props.imageList.map((image) => {
        //return {src: image.originalSrc, description:'testing', key: image.id}
    //})

    let images = [];
    let counter = 0;

    Object.keys(props.imageList).forEach(key => {
        console.log(key, props.imageList[key]);
        images[counter] = {src: props.imageList[key].originalSrc, description: key, key: props.imageList[key].id}
        counter++;
    });

    function getWidth(element)
    {
        element.style.visibility = "hidden";
        document.body.appendChild(element);
        var width = element.offsetWidth + 0;
        document.body.removeChild(element);
        element.style.visibility = "visible";
        return width;
    }

    function placeImages(){
        //Get the size of the box
        const frame = document.querySelector('.page-journal').getBoundingClientRect(); 
        const frameWidth = frame.width;
        const frameHeight = frame.height;
        const lowerHeightLimit = 150;

        //get all images
        const dragBoxes = document.querySelectorAll('.draggable');

        //cycle through images
        [].forEach.call(dragBoxes, (dragBox) => {
            //Determine the height of the Boxes
            let heightRange = frameHeight - lowerHeightLimit  
            let initialBoxHeight = heightRange * Math.random();

            dragBox.style.height = (0.75 * (lowerHeightLimit + initialBoxHeight)) + 'px';

            let dragBoxRect = dragBox.getBoundingClientRect();
            
            //get the width and height of the boxes
            let boxWidth = dragBox.width;
            let boxHeight = dragBoxRect.height;
            console.log(boxWidth);
            
            let xAxis = Math.random();
            let yAxis = Math.random();

            //Place the image
            let xPlacement = xAxis * (frameWidth - boxWidth);
            let yPlacement = yAxis * (frameHeight - boxHeight);
            dragBox.style.left = xPlacement + 'px';
            dragBox.style.top = yPlacement + 'px';
        })
    }

    function placeImage(event){
        const dragBox = event.target;
        console.log(isMobile);
        if(!isMobile){
            //Get the size of the box
            const frame = document.querySelector('.page-journal').getBoundingClientRect(); 
            const frameWidth = frame.width;
            const frameHeight = frame.height;
            const lowerHeightLimit = 150;
            
            console.log(event);

            //Determine the height of the Boxes
            let heightRange = frameHeight - lowerHeightLimit  
            let initialBoxHeight = heightRange * Math.random();

            dragBox.style.height = (0.75 * (lowerHeightLimit + initialBoxHeight)) + 'px';

            let dragBoxRect = dragBox.getBoundingClientRect();
            
            //get the width and height of the boxes
            let boxWidth = dragBox.width;
            let boxHeight = dragBoxRect.height;
            console.log(boxWidth);
            
            let xAxis = Math.random();
            let yAxis = Math.random();

            //Place the image
            let xPlacement = xAxis * (frameWidth - boxWidth);
            let yPlacement = yAxis * (frameHeight - boxHeight);
            dragBox.style.left = xPlacement + 'px';
            dragBox.style.top = yPlacement + 'px';
        }
        fadeBoxTL.fromTo(dragBox, {opacity:0}, {opacity:1, duration: 0.4});
    }


    function changeFootnote(event){
        const desc = event.target.dataset.description;
        console.log(desc);
        const footNoteContainer = document.querySelector('.footnotes p')

        footNoteContainer.innerHTML = desc;
    }

    function handleImageClick(event){
        const dragBoxes = document.querySelectorAll('.dragbox');
        [].forEach.call(dragBoxes, (dragBox) => {
            dragBox.style.zIndex = 1;
        })
        event.target.style.zIndex=2
    }

    let img = images.map( (image) =>{
        //Create  random x and Y coordinate to drp them in 
        let left = Math.random()*100;
        let top = Math.random()*100;
        if(!isMobile){
            return  <img onMouseEnter={changeFootnote} onLoad={(event) => {placeImage(event)}} className={boxClasses} src={image.src} onMouseDown={handleImageClick} data-description={image.description} key={image.key} />
        }else{
            return  <><img onMouseEnter={changeFootnote} onLoad={(event) => {placeImage(event)}} className={boxClasses} src={image.src} onMouseDown={handleImageClick} data-description={image.description} key={image.key} /><p>{image.description}<br /><br /><br /><br /></p></>
        }
    })

    let imagePlace;
    window.addEventListener('resize', function(){
        if(!isMobile && document.querySelector('.page-journal')){
            clearTimeout(imagePlace);
            imagePlace = setTimeout(() => {
                placeImages();
            }, 200)
        }
    })

    function fadeIn(){
        const images = document.querySelectorAll('.dragbox');
        [].forEach.call(images, (image) => {    
            image.style.opacity = 0;
        })

        gsap.fromTo(images, {opacity:0}, {opacity:1, stagger: 0.4, duration: 0.4});
    }
    function introAnim(){
    }
    function exitAnim(){
        //gsap.from(animatedDropdowns, {y:10, stagger:0.2, opacity:0})
        const images = document.querySelectorAll('.dragbox');

        gsap.to(images, {y:10, stagger:0.1, opacity:0})
        gsap.to('.footnotes', {y:10, stagger:0.2, opacity:0})
    }

    function animations(type){
        if(type=='entering'){
            setTimeout(() => {
                introAnim();
            }, 1000);
        }

        if(type=='exiting'){
            exitAnim();
        }
    }
    
    useLayoutEffect(() => {
        if(!isMobile){
            draggable();
        }
        //placeImages();
        //fadeIn()
    },[])

    useEffect(() => {
        animations(props.transState);
    },[props.transState])

    return (
        <div className={pageClasses}>
            <div className="page-journal">
                {img}
            </div>
                {!isMobile && (
                    <div className="footnotes">
                        <p>
                            
                        </p>
                    </div>
                )
            }
        </div>
    );
}

export default JournalPage;