import React, { useRef } from 'react';
import ProductCard from '../ProductCard';
import arrow from './down-arrow.svg';

function Dropdown(props) {
    const selectValue = useRef(false);
    let i = 0;
    const dropdownValues = props.values ? props.values.map((val) => { i++; if(i == props.selected){ return <option value={val.title || val} selected>{val.title || val}</option> }else{ return <option value={val.title || val}>{val.title || val}</option>}}) : <option value=''>ERROR</option>
    function updateHolderValue(updateFunc, value){
        updateFunc(value)
    }

    return (
        <div>
            <div class="dropdown">
                <select ref={selectValue} onChange={() => {props.updateCorrValue(selectValue.current.value)}} name="cars" id="cars">
                    {dropdownValues}
                </select>
                <img src={arrow} />
            </div>
        </div>
    );
}

export default Dropdown;