import React, { useEffect, useRef } from 'react';
import homepage from './HOMEPAGE.jpg';
import gsap, { Power1 } from 'gsap';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import userEvent from '@testing-library/user-event';
import { Transition } from 'react-transition-group';
import raw from "raw.macro";

gsap.registerPlugin(ScrollToPlugin);

function HeroHeader(props) {
    const markdown = raw("./hero.json");
    const hero_details = JSON.parse(markdown);
    console.log('hero props', props.hero);
    //const image = <img src={hero_details.hero_url} />;
    const image = <img src={props.hero.hero_url} />;
    const video = <video id="player" playsInline autoPlay loop muted data-poster="https://benkelwaystudio.com/wp-content/themes/atlas/assets/img/transparent.png"><source src={props.hero.hero_url + ".mp4"} type="video/mp4" /><source src={props.hero_url + ".webm"} type="video/webm" /><source src={props.hero_url + ".ogv"} type="video/ogv" /></video>;
    const classHeader = props.hero.fullscreen ? 'header fullscreen' : 'header' ;
    const heroHeader = useRef(null);
    const media = props.hero.hero_type === 'video' ? video : image;
    
    function introAnim(){
        //gsap.from(animatedDropdowns, {y:10, stagger:0.2, opacity:0})
        //props.introAnimation.fromTo(heroHeader.current, {y:10, opacity:0},  {y:0, stagger:0.2, opacity:1})
        gsap.fromTo(heroHeader.current, {y:10, opacity:0},  {y:0, stagger:0.2, opacity:1})
    }

    function exitAnim(){
        //gsap.from(animatedDropdowns, {y:10, stagger:0.2, opacity:0})
        //props.introAnimation.to(heroHeader.current, {y:10, stagger:0.2, opacity:0})
        gsap.to(heroHeader.current, {y:10, stagger:0.2, opacity:0})
    }

    useEffect(() => {
        animations(props.transState);
    },[props.transState])

    function animations(type){
        if(type=='entered'){
            introAnim();
        }
        if(type=='exiting'){
            exitAnim();
        }
    }

    return (
        <div className={classHeader}>
            <div className="header__herowrapper">
            <div className="header__hero animated--fadeIn" ref={heroHeader} onClick={ () => { gsap.to(window, {duration: 1, ease: "power1.inOut", scrollTo: '.product-listing-wrapper'}); }}>
                {media}
            </div>
            </div>
        </div>
    );
}

export default HeroHeader;