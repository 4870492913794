import React from 'react';
import { client } from '../../../modules/store';

function CheckoutBtn(props) {

    const dispatchOrderToShopify = () => {

        const myCheckout = client.checkout;

        myCheckout.create().then((checkout) => {
            const checkoutId = checkout.id;
            const input = {customAttributes: [{key: "MyKey", value: "MyValue"}]};

            const lineItemsToAdd = props.cart.map((cartItem) => { return { variantId : 'gid://shopify/ProductVariant/' + cartItem.varID, quantity : parseInt(cartItem.quantity), customAttributes : [{key: "size", value: String(cartItem.size.title)}] }})

            console.log('line items',props.cart);
            
            myCheckout.updateAttributes(checkoutId, input).then((checkout) => {
                myCheckout.addLineItems(checkoutId, lineItemsToAdd).then((checkout) => {

                    window.open(checkout.webUrl);

                });
            });        
        });    
    }

    return (
        <button className="" onClick={() => {dispatchOrderToShopify()}}>CHECKOUT</button>
    );
}

export default CheckoutBtn;