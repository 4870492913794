import { _removeLinkedListItem } from 'gsap/gsap-core';
import React from 'react';
import Dropdown from '../../Dropdown';
import prodImage from '../product1.jpg';

function CartItem(props) {
    function removeItem(productID){
        console.log(productID)
        let newCart = [...props.cart];
        let prod2Remove = props.cart.findIndex(prodObj => prodObj.prodID === productID);

        newCart.splice(prod2Remove, 1);
        props.updateCart(newCart);
    }

    function onChangeUpdateSize(newSize){

    }

    function onChangeUpdateQuantity(newQuantity){
        const index = props.cart.findIndex(cartItem => cartItem.prodID === props.item.prodID && cartItem.size === props.item.size);

        let newCart = [...props.cart]
        newCart[index].quantity = newQuantity;

        window.sessionStorage.setItem('ophiucus-cart', JSON.stringify(newCart));

        props.updateCart(newCart);

        return newCart;
    }

    console.log('item', props.item);

    return (
        <div className="cartitem">
            <table>
                <tr>
                    <td>
                        <div className="cartitem__image">
                            <img src={props.item.image} />
                        </div>
                    </td>
                    <td>
                        <div className="cartitem__details">

                            <div>
                                <p>{props.item.name}</p>
                                <p>{props.item.price + ' GBP'}</p>
                                <div>                                
                                    <p>
                                        SIZE {' ' + props.item.size.title}
                                        {
                                            /*
                                                <Dropdown values={[1,2,3,4,5,6,7]} selected={props.item.size} updateCorrValue={onChangeUpdateSize}></Dropdown>
                                            */
                                        }
                                    </p>   
                                </div>
                                <p>
                                    QUANTITY {' ' + props.item.quantity}
                                    <Dropdown values={[1,2,3,4,5,6,7,8,9]} selected={props.item.quantity} updateCorrValue={onChangeUpdateQuantity}></Dropdown>
                                </p>
                            </div>
                            
                            <a href="#"><p className="underline" onClick={(e) => {e.preventDefault(); removeItem(props.item.prodID)}}>Remove</p></a>
                            
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    );
}

export default CartItem;