import React, {useEffect} from 'react';
import gsap from 'gsap';

function WrittenContent(props) {
    function introAnim(){
        gsap.fromTo('.text-holder', {y:10, opacity:0}, {y:0, opacity:1})
    }

    function exitAnim(){
        gsap.fromTo('.text-holder', {y:0, opacity:1}, {y:10, opacity:0})
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        animations(props.transState);
    },[props.transState])

    function animations(type){
        if(type=='entered'){
            introAnim();
        }
        if(type=='exiting'){
            exitAnim();
        }
    }

    return (
        <div className="text-holder">
            {props.children}
        </div>
    );
}

export default WrittenContent;