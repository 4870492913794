import React, { useState, useEffect, useRef } from 'react';
import Swiper from 'swiper/bundle';
// import Swiper styles
import 'swiper/swiper-bundle.css';
import prodImage from './product1.jpg';
import ZoomView from './ZoomView';

function ProductSlider(props) {
    const [fullScreenImage, updateFullScreenImage] = useState(null);
    const [isVisible, showZoomScreen] = useState(false);
    const bullets = useRef(null);
    const sliderImages = props.images ? props.images.map((image) => <div className="swiper-slide"><div><img src={image} onClick={ () => { updateFullScreenImage(image); showZoomScreen(true); }} /></div></div>) : <div className="swiper-slide"><div><img src={prodImage} onClick={ () => { updateFullScreenImage(prodImage); showZoomScreen(true); }} /></div></div>

    function introAnim(){
        const bulletList = bullets.current.children;
        console.log(bulletList);

        //props.introAnimation.from(bulletList, {stagger:0.2, opacity:0})
    }
    useEffect(() =>{

        const swiper = new Swiper('.swiper-container', {
            hashNavigation: {
                watchState: true,
            },
            // Optional parameter
            observer: true,
            // If we need pagination
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });

        introAnim();
        
    });
    return (
        <>
        <ZoomView image={fullScreenImage} images={props.images} isShown={isVisible} exitFunc ={showZoomScreen} ></ZoomView>
        <div className="page-product__gallery__content swiper-container">
            <div className="swiper-wrapper">
                {sliderImages}
            </div>
            <div class="swiper-pagination" ref={bullets}></div>
            <div class="swiper-button-prev">
            </div>
            <div class="swiper-button-next">
            </div>
        </div>
        </>
    );
}

export default ProductSlider;