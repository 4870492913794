import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import gsap from 'gsap';

function ProductCard(props) {
    const prodCard = useRef(null);

    function getPermalinkFromName(name){
        name = name.toLowerCase();

        let nameArray = name.split(" ");

        let permalink = nameArray.join("-");

        return permalink
    }

    useEffect(() => {
        setTimeout(() => {
            const cardAnim = gsap.fromTo(prodCard.current, {y:10, opacity: 0}, {y:0, opacity: 1}).pause();

            (() => {
                const options = {
                root: null,
                rootMargin: '-100px',
                threshold: 0.1,
                };
        
                const observer = new IntersectionObserver((entries) => {
                    if(entries[0].isIntersecting){
                        cardAnim.play();
                        console.log(entries[0])
                    }
                    /*
                entries.forEach(function (entry) {
                    if (entry.isIntersecting) {
                        console.log(entries);
                        console.log(prodCard.current);
                        gsap.to(entry.target, {opacity: 1})
                    }
                }); */
                }, options);
        
                observer.observe(prodCard.current);
            })();
        }, 2000)
        
    },[])

    return (
        <div className={`product-card spanning-${props.gridspan}`} ref={prodCard}>
            <Link to={'shop/' + getPermalinkFromName(props.name)}>
                <div className="product-card__imagewrapper">
                    <img onLoad={() => { props.onLoad() }} src={props.image} />
                </div>
                <div className="titleinfo"><p>{props.name}</p><p>{props.price}</p></div>
                <p className='product-card__description'>{props.description}</p>
            </Link>
        </div>
    );
}

export default ProductCard;